<script>
	import { createEventDispatcher, onDestroy } from 'svelte';
	import { writable } from 'svelte/store';
	import { fly, fade } from 'svelte/transition';
	import { onMount, setContext } from 'svelte';
	import { IsNative, RenderDelayedContent } from '$lib/store.js';

	export let show = false;
	$: setContext('show', writable(show));

	const dispatch = createEventDispatcher();
	export let allowClose = true;
	export let clickThrough = false;
	export let showBg = true;
	export let bgClasses = '';
	export let closeText = undefined;
	export let absolute = false;
	export let seeThrough = false;
	export let options = {
		seeThrough: false,
		topLevel: false,
		fullScreen: false,
		cssText: '',
		classes: ''
	};
	let id = 'Modal' + (options.name || Math.round(Math.random() * 10000));
	const close = () => {
		if (!allowClose) return;
		show = false;
		console.log('closing modal');
		// make sure it's closed after 2 second
		setTimeout(() => {
			let modal = document.getElementById(id);
			if (modal && modal?.style?.opacity)
				modal.style.cssText += 'opacity:0;pointer-events:none;z-index:-1;';
			let modalGb = document.getElementById(id + 'bg');
			if (modalGb && modalGb?.style?.opacity)
				modalGb.style.cssText += 'opacity:0;pointer-events:none;z-index:-1;';
		}, 2000);
		return dispatch('close');
	};
	let modalBg;
	let modal;
	let parent;
	const appendChild = (nodeToAppendTo) => {
		if (!options.topLevel) return;
		parent = modal.parentNode;
		if (modalBg) (nodeToAppendTo || document.body).appendChild(modalBg);
		(nodeToAppendTo || document.body).appendChild(modal);
	};
	$: if (show && modal && options.topLevel) appendChild();
	$: if (show && modal && options.topLevel && options.pop && parent) appendChild(parent);

	const handle_keydown = (e) => {
		if (!show) return;
		// console.log({ e });
		if (e.key === 'Escape') {
			if (allowClose) close();
			e.stopPropagation();
			e.preventDefault();
			return;
		}

		if (e.key === 'Tab') {
			// trap focus
			const nodes = modal.querySelectorAll('*');
			const tabbable = Array.from(nodes).filter((n) => n.tabIndex >= 0);

			let index = tabbable.indexOf(document.activeElement);
			if (index === -1 && e.shiftKey) index = 0;

			index += tabbable?.length + (e.shiftKey ? -1 : 1);
			index %= tabbable?.length;

			tabbable[index].focus();
			e.preventDefault();
		}
	};

	const previously_focused = typeof document !== 'undefined' && document.activeElement;
</script>

<svelte:window on:keydown={handle_keydown} />
{#if !options.pop && showBg}
	<div
		class="fixed top-0 transition-opacity left-0 w-full h-full z-50 bg-black bg-opacity-50 {bgClasses}
{absolute ? ' absolute ' : ''}
{clickThrough ? ' pointer-events-none ' : ''}"
		style="transition-delay:200ms;{show && showBg ? '' : 'display:none;'}"
		on:click={() => {
			if (allowClose) close();
		}}
		bind:this={modalBg}
		id="{id}Bg"
	/>
{/if}
<!--  -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->

<div
	on:click|stopPropagation
	on:keydown|stopPropagation
	{id}
	class="transition-opacity shadow-2xl shadow-[#44494f66] {options.pop
		? ''
		: 'p-4 py-8'} z-50 w-full rounded-xl bg-[#0a0f1a] {seeThrough
		? 'bg-opacity-80'
		: ''} overflow-auto
  flex flex-col items-stretch {!(options.pop && !show)
		? ' left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 modal'
		: ''}  pointer-events-auto {options.classes} "
	style="transition-delay:100ms;{options.fullScreen && !(options.pop && !show)
		? `width:100vw;height: 100vh;bottom: 0;border-radius: 0;`
		: options.pop && !show
		  ? ''
		  : 'max-height: 90vh;max-width: clamp(320px, 95%, 56rem);'}
	{absolute ? 'position:absolute;' : 'position:fixed;'}
	{show || options.pop
		? ''
		: 'opacity:0;bottom:100%;pointer-events:none;max-width:100vw;z-index:0 !important;'}
	{options.cssText}"
	on:click|stopPropagation
	role="dialog"
	aria-modal="true"
	bind:this={modal}
>
	<div
		class="flex flex-col max-h-full min-h-full w-full items-stretch gap-4 justify-between"
		style={options.fullScreen && !(options.pop && !show)
			? 'height:calc(100vh - (env(safe-area-inset-top)));'
			: 'max-height:90vh;'}
	>
		{#if show || $RenderDelayedContent || options.pop}
			<!-- transition:fly|localStorage={{ duration: 250, delay: 0, y: 50 }} -->
			{#if allowClose}
				<button
					class="closeButton opacity-0 absolute z-50 shadow-none {$IsNative
						? `${options.fullScreen ? 'top-10' : 'top-2'} left-2 rounded-full p-3 bg-transparent`
						: 'top-3 right-3 p-2 text-xs bg-gray-800'} text-gray-400"
					on:click|stopPropagation={close}
				>
					{#if $IsNative}
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class={options.fullScreen ? 'h-9 w-9' : 'h-7 w-7'}
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					{:else}esc{/if}
				</button>
			{/if}
			<slot />
			{#if closeText}
				<button class="ml-auto" on:click={close}>{closeText}</button>
			{/if}
			<!-- svelte-ignore a11y-autofocus -->
		{/if}
	</div>
</div>

<style>
	.modal:hover .closeButton {
		opacity: 1;
	}
</style>
